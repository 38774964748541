const SidebarMenu = ({ children }) => {
  return (
    <nav>
      <div className="items-center hidden mb-6 sm:flex">
        <span className="mr-4 font-medium ">MENÜ</span>
        <span className="flex-1 border-b border-b-gray-200"></span>
      </div>
      <div className="flex flex-row justify-between overflow-x-scroll sm:justify-start sm:gap-3 no-scrollbar sm:flex-col whitespace-nowrap">
        {children}
      </div>
    </nav>
  );
};

export default SidebarMenu;
